<template>
  <div>
    <van-nav-bar class="first_head" fixed @click-right="onClickRight" :border = 'false'>
      <template #left>
        <img class="first_head_logo" src="@/assets/logo.png"/>
      </template>
      <template #right>
        <img class="first_head_ling" src="@/assets/first_customer_service.png"/>
<!--        <span class="first_head_count" style="margin-left: 5px;" v-html="msg_count"></span>-->
      </template>
    </van-nav-bar>
    <router-view style="padding-top: 80px;padding-bottom: 60px;"></router-view>
    <van-tabbar style="background: black;color: white" route active-color="#ee0a24"
                inactive-color="#fff" :border='false' v-model="active" @change="tabbar_change">
      <van-tabbar-item to="/home" class="">
        <span class="tabbar_text">{{$t('message.tab_home')}}</span>
        <template slot="icon">
          <img src="@/assets/tab_home.png" style="width: 25px;height: 25px;"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/exchange" class="">
        <span class="tabbar_text">{{$t('message.tab_exchange')}}</span>
        <template slot="icon">
          <img src="@/assets/tab_interest.png" style="width: 25px;height: 25px;"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/options" class="">
        <span class="tabbar_text">{{$t('message.tab_options')}}</span>
        <template slot="icon">
          <img src="@/assets/tab_start.png" style="width: 40px;height: 40px;margin-top: -30px;background: #dddddd;border-radius: 30px;padding: 10px;"/>
        </template>
      </van-tabbar-item>

      <van-tabbar-item to="/options" class="" v-if="false">
        <span class="tabbar_text">{{$t('message.tab_options')}}</span>
        <template slot="icon">
          <img src="@/assets/tab_start.png" style="width: 25px;height: 25px;"/>
        </template>
      </van-tabbar-item>

      <van-tabbar-item to="/contracts" class="">
        <span class="tabbar_text">{{$t('message.tab_contracts')}}</span>
        <template slot="icon">
          <img src="@/assets/tab_records.png" style="width: 25px;height: 25px;"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/user" class="">
        <span class="tabbar_text">{{$t('message.tab_profile')}}</span>
        <template slot="icon">
          <img src="@/assets/tab_profile.png" style="width: 25px;height: 25px;"/>
        </template>
      </van-tabbar-item>

    </van-tabbar>
  </div>

</template>

<script>
  import { checkMsgList } from '@/api/user';
  import store from "@/store";
  export default {
    name: "LayoutIndex",
    data(){
      return{
        msg_count: 0,
        active: 0
      }
    },
    methods:{
      onClickRight(){
        console.log('to msg list');
        this.$router.push('/customer_service');
      },
      tabbar_change(index){
        console.log('tabbar:',index);
      }
    },
    async created() {
      const token = this.$store.getters['user/token']
      if (token){
        const msglist_res = await checkMsgList()

        if(msglist_res.code == 0){
          this.msg_count = msglist_res.msg_num;
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../../css/pagestyle.less';

</style>
