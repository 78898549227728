<template>
  <div class="contracts first_page_margin">
    <div class="contracts_head">
      <div class="symbol_change">
        <van-icon class="icon" size="20px" name="search" @click="open_symbol_list" />
        <div class="symbol_title" v-html="target+'/'+base">
        </div>
      </div>
      <div class="symbol_quote_btn" @click="to_quote">
        <img src="@/assets/kline.png">
      </div>
    </div>

    <div class="contracts_content">
      <div class="left">
        <div class="left_title" style="margin-bottom: 10px;">
          <van-tabs v-model="left_active" background="rgba(0,0,0,0)" title-inactive-color="#888888"
                    :color="left_active_color" :title-active-color="left_active_color" @click="leftOprChange">
            <van-tab name="buy" :title="$t('message.contracts_left_buy')" />
            <van-tab name="sell" :title="$t('message.contracts_left_sell')" />
          </van-tabs>
        </div>
        <div class="left_item_mode_block">
          <div class="title">
            {{$t('message.contracts_left_mode_title')}}
          </div>
          <div class="value">
            <van-dropdown-menu>
              <van-dropdown-item style="width: 100px;background-color: unset;" v-model="mode_opt" :options="mode_options" />
            </van-dropdown-menu>
          </div>
        </div>

        <div class="left_item_price_block">
          <div class="title">
            {{$t('message.contracts_left_price_title')}}
          </div>
          <div class="input_block">
            <input class="input_text">
            <div class="input_mode" v-html="base"></div>
          </div>
        </div>
        <div class="left_item_leverage_block">
          <div class="title">
            {{$t('message.contracts_left_leverage_title')}}
          </div>
          <div class="value">
            <van-dropdown-menu>
              <van-dropdown-item style="width: 100px;background-color: unset;" v-model="leverage_opt" :options="leverage_options" />
            </van-dropdown-menu>
          </div>
        </div>
        <div class="left_item_number_block">
          <div class="title">
            {{$t('message.contracts_left_amount_title')}}
          </div>
          <div class="input_block">
            <input class="input_text">
            <div class="input_mode" v-html="target"></div>
          </div>

          <div class="input_slider">
            <van-slider bar-height="2px" active-color="#22dddd" style="margin: 20px 0;" v-model="amount_percent" @change="onAmountPercentChange">
              <div slot="button" style="" class="custom_slider_button"></div>
            </van-slider>
          </div>
        </div>

        <div class="left_item_usable_block">
          <div class="title">
            {{$t('message.contracts_left_usable')}}
          </div>
          <div class="text" v-html="usable_text"></div>
          <div class="mode" v-html="base"></div>
        </div>
        <div class="left_item_bond_block" style="margin-top: 10px;">
          <div class="title">
            {{$t('message.contracts_left_bond')}}
          </div>
          <div class="text" v-html="bond_text"></div>
          <div class="mode" v-html="base"></div>
        </div>
        <div class="left_item_fee_block" style="margin-top: 10px;">
          <div class="title">
            {{$t('message.contracts_left_fee')}}
          </div>
          <div class="text" v-html="fee_text"></div>
          <div class="mode" v-html="base"></div>
        </div>

        <van-button :class="exc_btn_class" :text="exc_btn_text"></van-button>

      </div>

      <div class="right">
        <div class="current_price_block" style="">
          <div class="current_price" v-html="current_price"></div>
          <div class="base" v-html="base"></div>
        </div>
        <div class="right_title_block" style="margin: 30px 0 10px 0;">
          <div class="right_title" v-html="$t('message.contracts_left_price_title')"></div>
          <div class="right_title" v-html="$t('message.contracts_left_number_title')"></div>
        </div>
        <div class="depth_sell_block" style="margin-bottom: 30px;">
          <div class="line" v-for="item in depth_sell_array" :key="item.price">
            <DepthItem :price="item.price" :vol="item.vol" type="1"></DepthItem>
          </div>
        </div>

        <div class="depth_buy_block" style="margin-top: 50px;">
          <div class="line" v-for="(item,index) in depth_buy_array" :key="index">
            <DepthItem :price="item.price" :vol="item.vol" type="2"></DepthItem>
          </div>
        </div>
      </div>
    </div>

    <div class="history_title_block" style="margin-bottom: 10px;">
      <van-tabs v-model="history_list_active" background="rgba(0,0,0,0)" title-inactive-color="#888888"
                :color="history_list_color" :title-active-color="history_list_color" @click="historyChange">
        <van-tab name="current_list" :title="$t('message.contracts_order_title1')" />
        <van-tab name="history_list" :title="$t('message.contracts_order_title2')" />
        <van-tab name="current_list" :title="$t('message.contracts_order_title3')" />
        <van-tab name="history_list" :title="$t('message.contracts_order_title4')" />
      </van-tabs>
      <div class="text_center" style="margin-top: 20px;" v-html="$t('message.home_market_quote_no_data')" />
    </div>


    <van-popup
      v-model="symbol_list_show"
      closeable
      close-icon-position="top-left"
      position="left"
      style="height: 100vh;width: 280px;">
      <div class="symbol_list_block">
        <van-cell class="symbol_list_item" v-for="(item,index) in symbol_list" @click="symbol_item_click(item.target, item.base)" :key="index">

          <quote-item :symbol_target="item.target"
                      :symbol_base="item.base" :price="item.price" :vol="item.vol" :change="item.percent">

          </quote-item>

        </van-cell>
      </div>

    </van-popup>

  </div>
</template>

<script>
import DepthItem from "@/components/DepthItem";
import QuoteItem from "@/components/QuoteItem";
import { depthData } from '@/api/quote';
import { getHomeQuote } from '@/api/home';
import store from "@/store";

export default {
  name: 'Contracts',

  components: {
    DepthItem,
    QuoteItem
  },

  created () {
    const symbolInfo = this.$store.getters['symbol/symbolInfo']
    // console.log("symbolInfo:",symbolInfo);
    this.target = symbolInfo.target;
    this.base = symbolInfo.base;
    if (symbolInfo.direct > 1){
      this.leftOprChange('buy');
    }
    else {
      this.leftOprChange('sell');
    }

    let that = this;

    this.depth_timer_handle = setInterval(function (){
      that.get_depth_data(that.target,that.base);
    },10000);
    console.log('options created.....'+this.depth_timer_handle);
  },
  data () {
    return {
      target:'BTC',
      base:'USDT',
      current_price:66826.0321,
      left_active:"buy",
      left_active_color:'#22aa66',
      mode_options:[
        { text: this.$t('message.contracts_left_mode_opt1'), value: 0 },
        { text: this.$t('message.contracts_left_mode_opt2'), value: 1 }
      ],
      mode_opt:0,
      leverage_options:[
        { text: 50, value: 0 },
        { text: 100, value: 1 }
      ],
      leverage_opt:0,
      amount_percent:0,
      price_input:0,
      usable_text:0,
      bond_text:0,
      fee_text:0,
      exc_btn_class:'buy_btn',
      exc_btn_text: this.$t('message.contracts_left_buy') + ' USDT',
      depth_buy_array:[],
      depth_sell_array:[],
      history_list_active:'current_list',
      history_list_color:'#22aaff',
      depth_timer_handle:0,
      symbol_list_show:false,
      symbol_list:[],
    }
  },
  methods: {
    leftOprChange(index, title) {
      this.left_active = index;
      if (index === 'buy'){
        this.left_active_color = '#22aa66';
        this.exc_btn_class = 'buy_btn';
        this.exc_btn_text = this.$t('message.contracts_left_buy') + ' ' + this.base;
      }
      else {
        this.left_active_color = '#ff2222';
        this.exc_btn_class = 'sell_btn';
        this.exc_btn_text = this.$t('message.contracts_left_sell') + ' ' + this.target;
      }
    },
    historyChange(index, title){

    },
    open_symbol_list(){
      this.symbol_list_show = true;
      this.refresh_list_quote();
    },
    symbol_item_click(target,base){
      this.symbol_list_show = false;
      this.target = target;
      this.base = base;
      this.leftOprChange(this.left_active,'');
      //todo refresh all data
    },
    onAmountPercentChange(value){
    },
    to_quote(){
      this.$router.push({
        path: '/quote',
        query: {
          target: this.target,
          base: this.base,
          pos:3,
        }
      })
    },
    async get_depth_data($target,$base){
      const depth_data = await depthData($target,$base)
      console.log(depth_data);
      if(depth_data.code == 0){
        this.current_price = depth_data.curren_price;
        this.depth_buy_array = depth_data.depth_buy_array;
        this.depth_sell_array = depth_data.depth_sell_array;
      }
    },
    async refresh_list_quote(){
      const home_quote = await getHomeQuote()
      console.log(home_quote);
      if(home_quote.code == 0){
        this.symbol_list = home_quote.list_quote;
      }
    }
  },
  mounted() {
    this.get_depth_data(this.target,this.base);
  },
  beforeDestroy(){
    clearInterval(this.depth_timer_handle);
  },
  filters: {
    rounding4(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        realVal = parseFloat(value).toFixed(4);
      } else {
        realVal = "--";
      }
      return realVal;
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.contracts {
  input{
    border: none;
    outline: none;
    background: unset;
  }
}

.contracts_head{
  .page_flex();
  .page_flex_row();

  padding: 10px;

  .symbol_change{
    .page_flex();
    .page_flex_row();
    .page_flex_grow1();

    .icon{
      padding-top: 10px;
      color: @positive_color;
    }

    .symbol_title{
      padding-left: 10px;
      line-height: 40px;
    }
  }

  .symbol_quote_btn{
    text-align: right;
    img{
      width: 40px;
    }
  }
}

.contracts_content{
  .page_flex();
  .page_flex_row();

  .left{
    width: 50%;

    padding: 0 20px;

    .left_title{

    }
  }

  .right{
    width: 50%;

    padding: 10px;

    .current_price_block{
      .page_flex();
      .page_flex_row();

      .current_price{
        color: @negative_color;
        font-size: 18px;
        line-height: 21px;
      }

      .base{
        line-height: 21px;
        padding-left: 10px;
        font-size: 14px;
        color: #888888;
      }
    }

    .right_title_block{
      .page_flex();
      .page_flex_row();
      .text_center();
      font-size: 14px;
      color: #888888;

      .right_title{
        width: 50%;
      }
    }

  }
}

.left_item_leverage_block,
.left_item_mode_block{
  padding: 5px 0 5px 0;
  .title{
    color: @contracts_subtitle_color;
  }
}

.left_item_number_block,
.left_item_price_block{
  padding: 5px 0 5px 0;
  .title{
    color: @contracts_subtitle_color;
  }

  .input_block{
    .page_flex();
    .page_flex_row();

    .input_text{
      width: calc(100% - 29px) ;
      padding: 5px 0;
      color: white;
      border-bottom: 1px solid @contracts_subtitle_color;
    }

    .input_mode{
      color: #888888;
      font-size: 14px;
      padding: 5px;
      text-align: right;
    }
  }

  .input_slider{
    margin-top: 25px;
    padding-right: 5px;
  }
}

.left_item_fee_block,
.left_item_bond_block,
.left_item_usable_block{
  .page_flex();
  .page_flex_row();
  font-size: 14px;
  color: #888888;
  padding-right: 5px;

  .text{
    .page_flex_grow1();
    text-align: right;
    padding-right: 5px;
  }
}

::v-deep .van-dropdown-menu__bar{
  background-color: unset;
  width: 100%;
  height: 38px;
}

::v-deep .van-dropdown-menu__item{
  text-align: left;
  align-items: start;
  -webkit-box-align:start;
  -webkit-box-pack:start;
  justify-content:start;
}

::v-deep .van-dropdown-menu__title{
  width: calc(100% - 29px) ;
  color: white;
  padding: 5px 0;
  border-bottom: 1px solid @contracts_subtitle_color;
}

::v-deep .van-dropdown-menu__title::after{
  right: -10px;
}

.symbol_list_block{
  padding-top: 100px;
  background: @sub_bg;
  color: @sub_txt_color;

  .symbol_list_item{
    padding: 5px 10px;
    background: rgba(0,0,0,0);
  }
}

.custom_slider_button{
  width: 10px;
  height: 10px;
  background: @exchange_subtitle_color;;
  border-radius: 5px;
}
</style>
