<template>
  <div class="home first_page_margin">

    <van-swipe :autoplay="3000" :height="260" class="banner_img">
      <van-swipe-item style="text-align: center;" v-for="(image, index) in images" :key="index">
        <img style="height: 260px;" :src="image" />
      </van-swipe-item>
    </van-swipe>

    <div class="home_running_tips">
      <van-notice-bar :text="running_tips" left-icon="bell" />
    </div>

    <div class="shortcut_symbol page_flex page_flex_row">
      <div v-for="short_symbol in short_symbol_list" :key="short_symbol.name" class="item"
        @click="to_quote(short_symbol.target,short_symbol.base)">
        <div class="name" v-html="short_symbol.name"></div>
        <div class="price">
          <TickerText :text_num="short_symbol.price"></TickerText>
        </div>
        <div class="percent">
          <TickerPercent :text_num="short_symbol.percent"></TickerPercent>
        </div>
        <div class="vol" v-html="short_symbol.vol+'K'"></div>
      </div>
    </div>

    <div class="home_recharge_block" @click="to_recharge">
      <div class="home_recharge_btn">
        {{$t('message.home_recharge_btn')}}
        <van-icon name="play-circle-o" />
      </div>

    </div>

    <div class="home_market_quote_block">
      <div class="home_market_quote_title">{{$t('message.home_market_quote')}}</div>
      <div class="home_market_quote_subtitle page_flex page_flex_row text_center">
        <div class="column" style="width: 25%;">
          {{$t('message.home_market_quote_pair')}}
        </div>
        <div class="column" style="width: 50%;">
          {{$t('message.home_market_quote_price')}}
        </div>
        <div class="column" style="width: 25%;">
          {{$t('message.home_market_quote_change')}}
        </div>
      </div>

      <van-list
        v-model="quote_loading"
        :finished="quote_finished"
        :finished-text="$t('message.home_market_quote_no_more')"
        @load="onLoad">
        <div v-for="item in market_quote_list">

          <quote-item class="home_quote_item" :symbol_target="item.target"
                      :symbol_base="item.base" :price="item.price" :vol="item.vol" :change="item.percent"
                      @click="to_quote(item.target,item.base)">
          </quote-item>

        </div>
      </van-list>

      <div class="site_desc_block">
        <div class="title" v-html="$t('message.home_desc_title')"></div>
        <div class="item" style="margin-top: 20px;">
          <div class="item_icon"><img src="@/assets/home_security.png"></div>
          <div class="item_desc">
            <div class="item_desc_title" v-html="$t('message.home_desc_subtitle1')"></div>
            <div class="item_desc_value" v-html="$t('message.home_desc_sub_value1')"></div>
          </div>
        </div>
        <div class="item">
          <div class="item_icon"><img src="@/assets/home_time.png"></div>
          <div class="item_desc">
            <div class="item_desc_title" v-html="$t('message.home_desc_subtitle2')"></div>
            <div class="item_desc_value" v-html="$t('message.home_desc_sub_value2')"></div>
          </div>
        </div>
        <div class="item">
          <div class="item_icon"><img src="@/assets/home_global.png"></div>
          <div class="item_desc">
            <div class="item_desc_title" v-html="$t('message.home_desc_subtitle3')"></div>
            <div class="item_desc_value" v-html="$t('message.home_desc_sub_value3')"></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import TickerText from "@/components/TickerText";
import TickerPercent from "@/components/TickerPercent";
import QuoteItem from "@/components/QuoteItem";

import { getHomeData,getHomeQuote } from '@/api/home';

export default {
  name: 'HomePage',

  components: {
    QuoteItem,
    TickerText,
    TickerPercent,
  },

  data() {
    return {
      running_tips: '',
      images: [],
      short_symbol_list:[
      ],
      market_quote_list:[],
      quote_loading: false,
      quote_finished: false
    }
  },
  methods:{
    onLoad(){
      this.market_quote_list = [
      ];
      this.quote_loading = false;
      this.quote_finished = true;
    },
    to_quote(target,base){
      // console.log('item click:');
      console.log('item click:' + target + ',base:' + base);
      this.$router.push({
        path: '/quote',
        query: {
          target: target,
          base: base,
          pos:2,
        }
      })
    },
    to_recharge(){
      this.$router.push('/recharge');
    },
    async refresh_data(){
      const home_data = await getHomeData()
      console.log(home_data);
      if(home_data.code == 0){
        this.images = home_data.img_arr;
        this.running_tips = home_data.run_tips;
      }
    },
    async refresh_quote(){
      const home_quote = await getHomeQuote()
      console.log(home_quote);
      if(home_quote.code == 0){
        this.short_symbol_list = home_quote.shortcut_quote;
        this.market_quote_list = home_quote.list_quote;
      }
    }
  },
  created() {
    this.refresh_data();
    this.refresh_quote();
  },
  mounted(){
  },
  unmounted(){
  }
}
</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.home_content{
}

.banner_img{
  //background: @banner_bg;
  padding: 10px 0;
}

.van-notice-bar{
  background-color: unset;
}

.home_running_tips{
  background: @running_text_bg;
  margin: 20px 30px 10px 30px;
  border-radius: 10px;
}

.shortcut_symbol{
  padding: 10px 0;

  .item{
    width: 33.33%;
    text-align: center;

    .name{
      color: @symbol_color;
      font-size: 18px;
      //text-align: left;
    }

    .price{
      padding-top: 5px;
      //text-align: left;
    }

    .percent{
      padding-top: 5px;
      //text-align: left;
    }

    .vol{
      padding-top: 5px;
      //text-align: left;
      color: @symbol_color;
    }
  }
}

.home_recharge_block{
  background-image: url('@/assets/home_recharge.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  //background-attachment: fixed;
  color: @home_recharge_color;
  padding: 20px;
}

.home_market_quote_block{
  padding: 10px 20px;

  .home_market_quote_title{
    font-size: 18px;
    font-weight: bold;
  }

  .home_market_quote_subtitle{
    padding: 10px 0;
    .column{
      color: @home_market_quote_subtitle;
    }
  }
}

.home_quote_item{
  padding: 10px 0;
}

.site_desc_block{
  padding: 10px;
  .title{
    text-align: left;
    font-size: 20px;
  }

  .item{
    padding: 10px 0;
    .page_flex();
    .page_flex_row();

    .item_icon{

      img{
        width: 60px;
      }
    }

    .item_desc{
      padding-left: 10px;

      .item_desc_title{
        font-size: 18px;
      }

      .item_desc_value{
        font-size: 14px;
        color: @home_market_quote_subtitle;
      }
    }
  }
}

</style>
